@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*{
  font-family: "Montserrat",ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-weight: 400;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background-color: #e4e4e4;
  border-radius: 100px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #7F56D9E5;
  border-radius: 100px;

}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.ol-zoom {
  /* top: 6.0em !important;
  left: 1.3em !important; */
  display: none !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  margin-top: 40px;
  margin-bottom: 10px;
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background:  #7F56D9E5;  
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #7F56D9E5;  
}