/* <!-- HTML !-->
<button class="button-3" role="button">Button 3</button> */

/* CSS */
.button-3 {
  appearance: none;
  background-color: #8B5CF6;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.5em;
  padding: 0.6em 0.6em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 1em;
  margin-top: 1em;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(139, 92, 246, .4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #a08bd0;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color: #6d46c8;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}